import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConflictError, ConflictErrorType } from '@remento/types/error';

import { toast } from '@/components/RMToast/RMToast';
import { getFormValue } from '@/modules/form/form';
import { getInputInteraction, setInputValue } from '@/modules/form/input';
import { LegacyOnboardingFinish } from '@/modules/onboarding/components/LegacyOnboardingFinish/LegacyOnboardingFinish';
import { useOnboardingForm } from '@/modules/onboarding/onboarding.form.context';
import { getSetupPromptTypeSelectionPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { CheckoutType } from '@/services/api/checkout';
import { captureException } from '@/utils/captureException';
import { secureUuid } from '@/utils/uuid';

export function LegacyOnboardingFinishPage() {
  const { checkoutService, onboardingAnalyticsService } = useServices();

  const user = useUser();
  const navigate = useNavigate();
  const form = useOnboardingForm();

  const handleSubmit = useCallback(async () => {
    const onboardingFormValue = getFormValue(form);
    if (!onboardingFormValue) {
      throw new Error('No onboarding data found');
    }

    if (!user) {
      throw new Error('User not found');
    }

    try {
      const projectId = user.availableSubscriptions[0].seriesId;
      await checkoutService.legacyFinishCheckout({
        type: CheckoutType.BIOGRAPHY,
        subscriptionId: user.availableSubscriptions[0].id,
        recipient: onboardingFormValue.recipient,
        gift: onboardingFormValue.gift,
      });

      navigate(getSetupPromptTypeSelectionPath(projectId));
      onboardingAnalyticsService.onOnboardingCompleted({
        storyteller: onboardingFormValue.recipient.email,
        sendGift: onboardingFormValue.gift.sendOn,
        giftCustomized: getInputInteraction(form, 'gift.message').isDirty,
      });
    } catch (error) {
      if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_ALREADY_EXISTS) {
        toast('A storyteller with this email address already exists', 'root-toast', 'error');
        return;
      } else if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_SAME_USER) {
        toast('It is not possible to add yourself as a storyteller', 'root-toast', 'error');
        return;
      }
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [checkoutService, form, navigate, onboardingAnalyticsService, user]);

  // Set the initial storyteller name from the checkout flow
  useEffect(() => {
    if (!user || user.availableSubscriptions.length === 0) {
      return;
    }
    if (getInputInteraction(form, 'recipient.person.firstName').isTouched === false) {
      setInputValue(form, 'recipient.person.firstName', user.availableSubscriptions[0].storyteller?.firstName);
    }
    if (getInputInteraction(form, 'recipient.person.lastName').isTouched === false) {
      setInputValue(form, 'recipient.person.lastName', user.availableSubscriptions[0].storyteller?.lastName);
    }
  }, [form, user]);

  return <LegacyOnboardingFinish form={form} onSubmit={handleSubmit} />;
}
