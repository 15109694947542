import { useMemo } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';

import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { useIsFormValid } from '@/modules/form/form';
import { useInputValue } from '@/modules/form/input';
import { genitiveCase } from '@/utils/genitiveCase';

import { OnboardingForm } from '../../onboarding.form';

import {
  BackButton,
  Buttons,
  Field,
  Form,
  Header,
  HeaderLogo,
  Main,
  MobileHeader,
  ScrollArea,
  StyledPage,
} from './LegacyOnboardingFinish.styles';

export interface LegacyOnboardingFinishProps {
  form: OnboardingForm;
  onSubmit: () => void;
}

export function LegacyOnboardingFinish({ form, onSubmit }: LegacyOnboardingFinishProps) {
  const isMobile = useIsMobileViewport();

  const isValid = useIsFormValid(form);
  const firstName = useInputValue(form, 'recipient.person.firstName');

  const title = useMemo(() => {
    if (firstName) {
      return `Finalize the details of ${genitiveCase(firstName)} gift`;
    }
    return `Finalize the details of the gift`;
  }, [firstName]);

  const minDatePickerDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dayjs(today).format('YYYY-MM-DD');
  }, []);

  return (
    <StyledPage>
      {isMobile ? (
        <MobileHeader>
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <ScrollArea>
        <Main>
          <RMSpacer spacing={isMobile ? 'xl' : '2xl'} direction="column" />
          <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
            {title}
          </RMText>
          <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
          <Form>
            <InputContainer form={form} path="recipient.person.firstName">
              {(props) => (
                <Field size="small">
                  <RMInput
                    id="recipient-person-first-name"
                    label="Their first name"
                    placeholder="First name"
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="recipient.person.lastName">
              {(props) => (
                <Field size="small">
                  <RMInput id="recipient-person-last-name" label="Their last name" placeholder="Last name" {...props} />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="recipient.email">
              {(props) => (
                <Field size="large">
                  <RMInput
                    id="storyteller-email"
                    label="Their email"
                    placeholder="Email"
                    autoCapitalize="none"
                    type="email"
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="gift.sendOn">
              {(props) => (
                <Field size="large">
                  <RMInput
                    id="storyteller-start-date"
                    type="date"
                    label="Send my gift on"
                    placeholder="XX/XX/XXXX"
                    min={minDatePickerDate}
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="gift.from">
              {(props) => (
                <Field size="large">
                  <RMInput
                    id="gift-from"
                    label="From"
                    placeholder="Your name + anyone else the gift is from"
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="gift.message">
              {(props) => (
                <Field size="large">
                  <RMInput
                    id="storyteller-message"
                    label="Your message"
                    textarea
                    style={{ minHeight: 120 }}
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>

            {isMobile && <RMSpacer spacing="4xl" direction="column" />}
          </Form>

          <Buttons>
            <RMButton background="primary" fullWidth disabled={!isValid} onClick={onSubmit} autoLoading>
              Confirm
            </RMButton>
          </Buttons>
        </Main>
      </ScrollArea>
    </StyledPage>
  );
}
