import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--inverse-surface);
  width: 100%;
  height: 56px;
  flex: 0 0 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
    position: absolute;
  }
`;

export const BackButton = styled(FontAwesomeIcon)`
  margin-right: auto;
`;

export const ScrollArea = styled.div`
  overflow: auto;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: var(--spacing-2xl);

  body[data-mobile='true'] & {
    max-width: unset;
    padding: 0 var(--spacing-md);
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  justify-content: center;
  margin-top: var(--spacing-2xl);

  body[data-mobile='true'] & {
    padding: var(--spacing-sm) var(--spacing-md);
    border-top: 1px solid var(--border-hairline);
    background-color: var(--surface);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--spacing-xl);
  column-gap: var(--spacing-lg);
  width: 100%;
`;

export const Field = styled.div<{ size: 'small' | 'large' }>`
  grid-column: ${(props) => (props.size === 'small' ? 'span 1' : 'span 2')};

  @media only screen and (max-width: 768px) {
    grid-column: span 2;
  }
`;
